.root {
  position: absolute;
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: center;
}

.inner {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba(0, 0, 0, .2);
  border-radius: 100px 0px;
  overflow: hidden;
  cursor: pointer;
  background: #ccdfc7;
}

.inner .name {
  font-weight: bold;
}

.male {
  background: #ccdfc7;
}

.female {
  background: #ccdfc7;
}

.isRoot {
  border-color: rgba(0, 0, 0, .4);
}

.isHover {
  border-color: rgba(0, 0, 0, .8);
}

.id {
  font-size: 7px;
  line-height: 1;
  opacity: .5;
}

.sub {
  position: absolute;
  top: 6px;
  right: 14px;
  width: 14px;
  height: 10px;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 4px 0;
  background: #fff;
  cursor: pointer;
}

.sub.male {
  background: #ffffff00;
  border: 1px solid rgba(0, 0, 0, .2);
}

.sub.female {
  background: #a4ecff;
}

.content {  
  position: relative;
  width: 100%;  
  height: 100%;  
  text-align: center;
}

.myimg {  
  border-radius: 50%;
}