.text {
    text-overflow: ellipsis;
    overflow:hidden; 
    white-space:nowrap;
    width: 400px;
}
.img:hover {
    -webkit-box-shadow: 0px 0px 0px 2px rgba(34, 60, 80, 1) inset;
    -moz-box-shadow: 0px 0px 0px 2px rgba(34, 60, 80, 1) inset;
    box-shadow: 0px 0px 0px 2px rgba(34, 60, 80, 1) inset;
    padding: 5px;
}
.img:active {
    -webkit-box-shadow: 0px 0px 0px 2px rgba(34, 60, 80, 1) inset;
    -moz-box-shadow: 0px 0px 0px 2px rgba(34, 60, 80, 1) inset;
    box-shadow: 0px 0px 0px 2px rgba(34, 60, 80, 1) inset;
    background-color: lightgray;
    padding: 10px;
}


